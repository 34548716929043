import React, { Component } from 'react';
import Subtitle from '../components/subtitle/Subtitle';
import Privacy from '../containers/privacy/Privacy'
import NameForm from '../containers/name-form/name-form';
import axios from 'axios';

class UserNameCollection extends Component {
   constructor(props) {
      super(props);
      this.state = {
        email: '',
        firstName: '',
        error: '',
      };
    }
  
    continue = async (e) => {
      e.preventDefault();
      const { nextStep } = this.props;
  
   nextStep();
   };

   render() {
      const { values, handleChange, nextStep } = this.props;

      return (
         <div>
            <Subtitle text="Casi hemos terminado... Escribe tu nombre." />
            <form style={{margin:"1rem 0 2rem 0 "}} onSubmit={this.continue}>
               <NameForm
                  values={values}
                  handleChange={handleChange}
                  nextStep={nextStep}
               />
               <Privacy />
            </form>
         </div>
      );
   }
}

export default UserNameCollection;