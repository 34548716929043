import React from "react";
import "./prices.css";
import { Fade } from "react-reveal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleClickScroll } from "../../utils/handleClickScroll";

const PriceComponent = (props) => {
  const theme = props.theme;
  return (
    <div className="main" id="prices">
      <div className="skills-header-div ">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"PACKS"}
        </h2>
        <Fade bottom duration={1000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            Packs de Servicios
          </h1>
        </Fade>
      </div>
      <div className="main-container-team">
        <Fade right duration={1000} distance="300px">
          <div
            className="container-integrant-min"
            style={{ backgroundColor: "rgba(57, 208, 193, 0.8)" }}
          >
            <h2>BASIC PACK:</h2>
            <h3>Estrategia y planificación del contenido</h3>
            <h3>2-3 publicaciones/reels/historias semanales</h3>
            {/* <h2
              style={{
                padding: "25px",
                position: "absolute",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              220€
            </h2> */}
          </div>
        </Fade>

        <Fade right duration={1000} distance="300px">
          <div
            className="container-integrant-medium"
            style={{ backgroundColor: "rgba(255, 28, 134, 0.8)" }}
          >
            <h2>COMMUNITY PACK:</h2>
            <h3>Briefing Marca </h3>
            <h3>Estrategia y planificación del contenido</h3>
            <h3>Estudio de clientes potenciales y actuales</h3>
            <h3>3-4 publicaciones/reels/historias semanales</h3>
            <h3>Reunión mensual y reformulación de los objetivos</h3>
            <h3>
              Estudio Inicial sobre su identidad en las redes{" "}
              <b>(Valorado 450€ INCLUIDO CON +3meses )</b>
            </h3>
            {/* <h2
              style={{
                padding: "25px",
                position: "absolute",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              320€
            </h2> */}
          </div>
        </Fade>

        <Fade right duration={1000} distance="300px">
          <div
            className="container-integrant-max"
            style={{ backgroundColor: "rgba(255, 255, 0, 0.8)" }}
          >
            <h2>INFLUENCER PACK: </h2>
            <h3>Briefing Marca </h3>
            <h3>Estrategia y planificación del contenido</h3>
            <h3>Estudio de clientes potenciales y actuales</h3>
            <h3>3-4 publicaciones/reels/historias semanales</h3>
            <h3>Reunión mensual y reformulación de los objetivos</h3>
            <h3>Creación de campañas personalizadas para su target.</h3>
            <h3>
              Estudio Inicial sobre su identidad en las redes{" "}
              <b>(Valorado 450€ INCLUIDO CON +3meses )</b>
            </h3>
            {/* <h2
              style={{
                padding: "25px",
                position: "absolute",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              400€
            </h2> */}
          </div>
        </Fade>
        <Fade right duration={1000} distance="300px">
          <div className="container-personalized">
            <h2>PERSONALIZED PACK: </h2>
            <h3>Briefing Marca </h3>
            <h3>Estrategia y planificación del contenido</h3>
            <h3>Estudio de clientes potenciales y actuales</h3>
            <h3>3-4 publicaciones/reels/historias semanales</h3>
            <h3>Reunión mensual y reformulación de los objetivos</h3>
            <h3>Creación de campañas personalizadas para su target.</h3>
            <h3>
              Estudio Inicial sobre su identidad en las redes{" "}
              <b>(Valorado 450€ INCLUIDO CON +3meses)</b>
            </h3>
            <h3>
              Servicios personalizados: Grabación presencial, sesion de fotos
              dirigida....
            </h3>
            <a
              onClick={() => handleClickScroll("contact")}
              class="about-us-button-white"
              style={{ color: theme.body, backgroundColor: theme.text }}
            >
              Contacta con nosotros
            </a>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default PriceComponent;
