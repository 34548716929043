import React from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Contact from "../../containers/contact/Contact";
import OurTeam from "../../containers/ourteam/OurTeam";
import Footer from "../../components/footer/Footer";
import CookiePolicy from "../../components/CookiesPolicy";
import MisionVisionValores from "../../containers/misionVisionValores/MisionVisionValores";
import PriceComponent from "../../components/prices/prices";
import ServiceGrid from "../../components/ServiceGrid/ServiceGrid";
import App from "../../components/Newsletter/App";

function Home(props) {
  const nebulaheroAnimation = {
    "0%": { backgroundPosition: "0 0" },
    "100%": { backgroundPosition: "100% 50%" },  };

  const herobgcolorStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to right, #39D0C1, #FF1C86, #FFFF00)",
    backgroundSize: "130% 100%", // Asegúrate de ajustar esta propiedad
    animation: "gradientAnimation 6s infinite ease-in-out",
  };

  return (
    <div style={herobgcolorStyle} className="herobgcolor" id="home">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <Greeting theme={props.theme} />
      <Skills theme={props.theme} />
      {/* <MisionVisionValores theme={props.theme} /> */}
      <ServiceGrid theme={props.theme}/>
      <PriceComponent theme={props.theme} />
      <App theme={props.theme}/>
      <Contact theme={props.theme} />
      {/* <OurTeam theme={props.theme} /> */}
      <Footer theme={props.theme} />
      <CookiePolicy />
      {/* <EmojiAnimation /> */}
    </div>
  );
}

export default Home;
