import React, { Component } from 'react';
import './Title.css';
import { Fade } from "react-reveal";

class Title extends Component {
	state = {}

	static defaultProps = {
		text: "Quieres seguir informado?"
	}

	render() {
		return (
			<div className="skills-header-div " id="newsletter">
			<h2 className="skills-heading pretitle" style={{ color: "rgb(52, 52, 52)" }}>
			  {"NEWSLETTER"}
			</h2>
			<Fade bottom duration={1000} distance="20px">
			  <h1 className="skills-header" style={{ color: "rgb(52, 52, 52)"  }}>
				{this.props.text}
			  </h1>
			</Fade>
		  </div>
		);
	}
}

export default Title;
