import React, { Component } from 'react';
import './congrats-subtitle.css';

class CongratsSubtitle extends Component {
   state = {}

   static defaultProps = {
      text: "Revisa tu bandeja de entrada y confirma tu email."
   }

   render() {
      return (
         <div >
            <p className="text">{this.props.text}</p>
         </div >
      );
   }
}

export default CongratsSubtitle;