import React from "react";
import "./Footer.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
/* eslint-disable jsx-a11y/accessible-emoji */
import { handleClickScroll } from "../../utils/handleClickScroll";

export default function Footer(props) {
  return (
    <footer class="footer-distributed">
      <div class="footer-left">
        <img
          loading="lazy"
          className="logo-img-footer"
          alt="logo"
          src={require("../../assests/images/logo.png")}
        />

        <p class="footer-links">
          <a onClick={() => handleClickScroll("home")}>Home</a>
          <a onClick={() => handleClickScroll("skills")}>Qué hacemos</a>
          <a onClick={() => handleClickScroll("newsletter")}>Newsletter</a>
          <a onClick={() => handleClickScroll("prices")}>Packs</a>
          <a onClick={() => handleClickScroll("contact")}>Contacto</a>
        </p>

        <p class="footer-company-name">Ysolve © 2021</p>
      </div>

      <div class="footer-center">
        <div>
          <i class="fa fa-map-marker"></i>
          <p>
            <span> Avenida Hermanos Machado 13, 204</span> Valencia, España
          </p>
        </div>

        <div>
          <a href="https://wa.me/604934292" target={"_blank"}>
            <i class="fa fa-phone"></i>
            <p> +34 627 669 080 / 604 93 42 92</p>
          </a>
        </div>

        <div>
          <i class="fa fa-envelope"></i>
          <p>
            <a href="mailto:aggressive.marketing.business@gmail.com">aggressive.marketing.business@gmail.com</a>
          </p>
        </div>
      </div>

      <div class="footer-right">
        <p class="footer-company-about">
          <span>Sobre Agressive Marketing</span>
          En Agressive Marketing, la innovación es nuestra esencia.{" "}
          <b>Anexo a Ysolve soluciones Tecnológicas.</b> Un equipo joven y
          experimentado dedicado a romper esquemas en el mundo del marketing.
          Desarrollamos estrategias disruptivas y creativas para catapultar tu
          marca hacia el éxito. Desde campañas de redes sociales hasta branding
          revolucionario, ofrecemos soluciones personalizadas que desafían la
          norma. Nuestra experiencia se traduce en resultados tangibles.
          Sumérgete en una nueva era de marketing con Agressive Marketing, donde
          cada proyecto es una oportunidad para destacar y reinventar el juego
          publicitario.
        </p>

        <div class="footer-icons">
          <a href="" target={"_blank"}>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <strong>{"linkedin"}</strong>
                </Tooltip>
              }
            >
              <li
                style={{
                  listStyle: "none",
                  marginLeft: "0.75rem",
                  fontSize: "20px",
                }}
                name={"linkedin"}
              >
                <span
                  className="iconify"
                  data-icon={"simple-icons:linkedin"}
                  data-inline="false"
                ></span>
              </li>
            </OverlayTrigger>
          </a>
        </div>
      </div>
    </footer>
  );
}
