import { useState } from 'react';
import './style.css';

function CookiePolicyContent({ onAccept }) {
  return (
    <div className="cookie-policy">
          <img  loading="lazy" style={{width:"4rem"}} src="https://cdn-icons-png.flaticon.com/512/1047/1047711.png" alt="cookies-img" />

      <p>Utilizamos cookies para mejorar su experiencia de navegación. Al continuar navegando, acepta nuestra política de cookies.</p>
      <button onClick={onAccept}>Aceptar</button>
    </div>
  );
}

export default function CookiePolicy() {
  const [accepted, setAccepted] = useState(false);

  function handleAccept() {
    setAccepted(true);
    localStorage.setItem('cookieAccepted', true);
  }

  if (accepted || localStorage.getItem('cookieAccepted')) {
    return null; // don't render anything if the cookie policy has been accepted
  }

  return <CookiePolicyContent onAccept={handleAccept} />;
}