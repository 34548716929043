import React from "react";
import "./App.css";
import Title from "./components/title/Title";
import UserNameCollection from "./pages/user-name-collection";
import UserEmailCollection from "./pages/user-email-collection";
import CongratsUser from "./pages/congratulations";
import axios from "axios";
//
class App extends React.Component {
  state = {
    step: 1,
    email: "",
    firstName: "",
  };

  // proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // handle change upon submit
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  // console.log user signup data
  signUp = () => {
    const { email, firstName } = this.state;
      try {
      const response = axios.post(
        "https://api.convertkit.com/v3/forms/6200627/subscribe",
        {
          first_name: firstName,
          email: email,
          api_key: "EGoeYGDwaMydskC2gwCzrQ",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      // Handle success, e.g., show a thank you message
    } catch (error) {
      console.error(error);
      // Handle error, e.g., show an error message
    }
  };

  render() {
    const { step, email, firstName } = this.state;
    const values = { email, firstName };

    switch (step) {
      case 1:
        return (
          <div className="appComponent" 
          style={{ color: "#000", backgroundColor: "#ccc" }}
          >
            <Title className="title" />
            <UserEmailCollection
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
            />
          </div>
        );

      case 2:
        return (
          <div className="appComponent">
            <Title />
            <UserNameCollection
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
            />
          </div>
        );

      case 3:
        return (
          <div className="appComponent">
            {this.signUp()}
            <Title text="Enhorabuena!" />
            <CongratsUser />
          </div>
        );
      default:
      // do nothing
    }
  }
}

export default App;
