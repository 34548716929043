import React from "react";
import "./ServiceGrid.css"; // Asegúrate de crear este archivo CSS
import { Fade } from "react-reveal";

const services = [
  {
    id: 1,
    title: "ChatBot IA",
    description: "Responde las preguntas de tus clientes 24h con Inteligencia Artifical.",
  },
  {
    id: 2,
    title: "Campaña Publicitaria",
    description: "Campañas y estrategias de anuncios desde Google Ads y Meta.",
  },
  {
    id: 3,
    title: "Linkedin Advance Search",
    description: "Uso de Linkedin Avanzado para contacto de posible empresas interesadas.",
  },
  {
    id: 4,
    title: "Diseño web UX/UI",
    description: "Crea una experiencia de usuario enfocada a obtención de clientes.",
    icon: "ino.png",
  },
  {
    id: 5,
    title: "Desarrollo y alojamiento Web",
    description: "Creamos y alojamos tu página web.",
    icon: "ino.png",
  },
  {
    id: 6,
    title: "Posicionamiento de marca",
    description: "Publicamos noticias en +50 periódicos digitales para posicionamiento orgánico de la marca.",
    icon: "ino.png",
  },
  {
    id: 7,
    title: "Desarrollo de Identidad",
    description: "Rediseñamos y modernizamos tu marca para hacerla más llamativa para tus clientes.",
    icon: "ino.png",
  },
  {
    id: 8,
    title: "Generación de contenido Presencial + Formación",
    description: "Te enseñamos y realizamos contigo contenido de calidad, en formato video.",
    icon: "ino.png",
  },
  {
    id: 9,
    title: "Posicionamiento orgánico ",
    description: "Aparición en noticias en +50 periódicos digitales para posicionamiento orgánico de la marca",
    icon: "ino.png",
  },
  // ... y así sucesivamente para los 16 servicios
];

const ServiceGrid = (props) => {
  const theme = props.theme;

  return (
    <>
      <div className="skills-header-div ">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"Servicios"}
        </h2>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            ¿Como te ayudamos?
          </h1>
        </Fade>
      </div>

      <div className="service-grid">
        {services.map((service) => (
        <Fade bottom duration={2000} distance="20px">
          <div
            key={service.id}
            className="service-item"
          >
            <img
              loading="lazy"
              src={require(`../../assests/svg/services/${service.id}.svg`)}
              alt={service.title}
              className="service-icon"
            />
            <div
              className="service-item-text"
            >
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </div>
        </Fade>
        ))}
      </div>
    </>
  );
};

export default ServiceGrid;
