import React, { Component } from 'react';
import './user-agreement.css';

class UserAgreement extends Component {
   state = {}
   render() {
      return (
         <div style={{alignSelf: "center"}}>
            <p className="userAgreement">
               I agree to receive information from Discovery Communications in accordance with the following Privacy Policy
            </p>
         </div>
      );
   }
}

export default UserAgreement;