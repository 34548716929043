import React, { Component } from 'react';
import Subtitle from '../components/subtitle/Subtitle';
import Privacy from '../containers/privacy/Privacy'
import EmailForm from '../containers/email-form/email-form';

class UserEmailCollection extends Component {
   state = {

   };

   continue = e => {
      e.preventDefault();
      const { nextStep } = this.props;
      nextStep();
   };

   render() {
      const { values, handleChange, nextStep } = this.props;

      return (
         <div>
            <Subtitle style={{textAlign: "center"}} text="Subscríbete a nuestro Newsletter y descubre OFERTAS" />
            <form style={{margin:"1rem 0 2rem 0 "}} onSubmit={this.continue}>
               <EmailForm
                  values={values}
                  handleChange={handleChange}
                  nextStep={nextStep}
               />
            </form>
         </div>
      );
   }
}

export default UserEmailCollection;