import React from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { aptitudes } from "../../portfolio";
import { Fade } from "react-reveal";
import FullStackImg from "./FullStackImg";
import CloudInfraImg from "./CloudInfraImg";
// import DesignImg from "./DesignImg";

function SkillSection(props) {
  const theme = props.theme;
  return (
    <div>
      <div class="container-services-main-skills">
        {aptitudes.map((aptitude) => {
          return (
            <Fade left duration={2000}>
              <div class="container-services" id="container-servs">
                <div>
                  <img  loading="lazy" alt="" src={require(`../../assests/svg/hacemos/${aptitude.id}.svg`)}/>
                  <div>
                    <h4>{aptitude.title}</h4>
                    <h5>{aptitude.subtitle}</h5>
                  </div>
                </div>
                <p style={{ color: theme.secondaryText }}>{aptitude.text}</p>
              </div>
            </Fade>
          );
        })}
      </div>
      {/* {skills.data.map((skill, index) => {
        return (
          <div className="skills-main-div who-are-we" id="who-are-we">
            <Fade left duration={2000}>
              <div className="skills-image-div">
                <GetSkillSvg fileName={skill.fileName} theme={theme} />
              </div>
            </Fade>
            <div className="skills-text-div article-who">
              <Fade right duration={1000}>
                <h2 className="skills-heading" style={{ color: theme.text }}>
                  {skill.pretitle}
                </h2>
                <h1 className="skills-heading" style={{ color: theme.text }}>
                  {skill.title}
                </h1>
              </Fade>
              <Fade right duration={1500}>
                <SoftwareSkill logos={skill.softwareSkills} />
              </Fade>
              <Fade right duration={2000}>
                <div>
                  {skill.skills.map((skillSentence, index) => {
                    return (
                      <p
                        id={index === 2 && "works"}
                        className="subTitle skills-text"
                        style={{ color: theme.secondaryText }}
                      >
                        {skillSentence}
                      </p>
                    );
                  })}
                </div>
              </Fade>
            </div>
          </div>
        );
      })} */}
    </div>
  );
}

export default SkillSection;
